























import { Component, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsHeatmap from '@/modules/campaigns/components/VsHeatmap/Index.vue'

@Component({
    name: 'Heatmap',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsHeatmap,
    },
})
export default class extends Vue {
    private loading = false
    private emailId = ''
    private mappedLinks: any[] = []
    private links: any[] = []
    $refs: any

    get campaignId () {
        return this.$route?.params?.campaignId
    }
}
